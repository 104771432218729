.Backend {
    width: 100vw;
    height: 100vh;
    background: $dark;
    display: flex;
    overflow: hidden;
    position: absolute;

    &-nav {
        height: 100%;
        min-width: 20em;
        padding: 1em;
        box-sizing: border-box;
        overflow-y: scroll;
        position: relative;

        @media (max-width: $lg) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            z-index: 199;
            background: $dark;
            display: none;
        }

        * {
            color: $base;
        }

        &-header {
            height: 4em;
            width: 100%;
            display: none;

            svg {
                height: 1.2em;
                margin: 0;
                color: $base;
            }

            @media (max-width: $lg) {
                display: block;
            }
        }

        &-logout {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 4em;
            background: $dark;
            padding-left: 1em;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }
    }

    &-content {
        width: calc(100% - 2em);
        height: calc(100% - 2em);
        background: $base;
        border-radius: $border_radius;
        margin: 1em;
        box-sizing: border-box;
        padding: 2em;
        overflow-y: scroll;

        @media (max-width: $lg) {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 1em;
            border-radius: 0;
        }

        &-header {
            height: 4em;
            width: 100%;
            display: none;

            svg {
                height: 1.2em;
                margin: 0;
            }

            @media (max-width: $lg) {
                display: block;
            }
        }
    }
}