.btn {
  &-outline {
    background: none;
    border: $border;
    border-color: $txt;
    color: $txt;

    &-base {
      border: $border;
      border-color: $base;
      color: $base;
    }

    &-danger {
      border-color: $danger;
      color: $danger;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &-base {
    background: $base;
    color: $prime;
    border-color: $base;
  }

  &-grey {
    background: rgba($txt, 0.1);
    color: $txt;
    border: none;
    // transition: all 0.2s;

    &:hover {
      box-shadow: none;
      // transform: scale(0.95);
    }
  }

  &-monochrome {
    background: rgba($prime, 0.15);
    border-color: transparent;
    color: $prime;

    &:hover {
      box-shadow: none;
      background: $prime;
      color: $base;
    } 
  }

  &-sm {
    padding: $padding_sm;
  }

  &-onclick {
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    color: $txt;
    display: inline;
    height: auto;
    width: auto;

    > svg, 
    > img {
      margin: 0;
      max-width: none;
      // height: auto;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &-info {
    background: rgba($txt, 0.15);
    color: $txt;
    padding: 0.1em 0.5em;
    font-size: 0.8em;
    font-weight: 700;
    border-radius: 2.5px;
    border: none;
    display: inline;

    &-prime {
      background: rgba($prime, 0.15);
      color: $prime;
    }

    &-second {
      background: rgba($second, 0.15);
      color: $second;
    }

    svg, 
    img {
      margin: 0;
      max-width: none;
      height: auto;
    }

    &:hover {
      box-shadow: none;
      cursor: default;
    }

    &-placeholder  {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-underlined {
    color: $txt;
    padding: 0;
    padding-bottom: 0.25em;
    border-radius: 0;
    background: none;
    border: none;
    border-bottom: $border;
    border-color: $txt;

    &:hover {
      box-shadow: none;
    }
  }
}
