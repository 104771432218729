.NormalItem {
  width: calc(33.33% - 2em);
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 1em;
  padding: 0 1.5em;
  padding-bottom: 5em;
  text-align: center;
  position: relative;

  &-gallery {
    position: relative;

    &-aspect {
      padding-bottom: 56.25%;
    }

    &-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    &-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  &-data {
    ul:first-of-type li {
      svg {
        width: 1em;
        padding: 0.1em;
        margin-right: 0.4em;
      }

      > div:first-of-type {
        max-width: 80%;
        overflow: hidden;

        p {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      > div:last-of-type {
        white-space: nowrap;
        text-align: right;
      }
    }

    &-icons {
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        position: relative;
        margin-right: 0.5em;

        svg {
          height: 1em;
          max-width: 1.2em;
        }

        &:hover {
          .NormalItem-data-icons-popup {
            display: inline;
          }
        }
      }

      &-popup {
        position: absolute;
        bottom: 1.7em;
        left: 0.5em;
        transform: translateX(-50%);
        box-shadow: $box_shadow;
        padding: 0.5em;
        border-radius: $border_radius;
        background: $base;
        display: none;
      }
    }
  }

  > a button {
    width: 11em;
  }

  > a:last-of-type {
    display: none;
    position: absolute;
    left: calc((100% - 11em) / 2);
    right: calc((100% - 11em) / 2);
    bottom: 1.5em;
  }

  &:hover {
    box-shadow: $box_shadow;

    > a:last-of-type {
      display: inline;

      @media (max-width: $med) {
        display: none;
      }
    }
  }

  @media (max-width: $lg) {
    width: calc(50% - 2em);
  }

  @media (max-width: $med) {
    margin-bottom: 2em;
    padding-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: none;
      cursor: pointer;

      > a:last-of-type {
        display: none;
      }
    }
  }

  @media (max-width: $sm) {
    width: calc(100% - 2em);
  }
}