$blur: blur(0.5em);

// Base
$base: #ffffff;
$base_hover: rgba(#ffffff, 0.75);
$base_disabled: rgba(#ffffff, 0.25);
.c-base {
  color: $base;
}
.bg-base {
  background: $base;
}
.bg-base-50 {
  background: rgba($base, 0.5);
  -webkit-backdrop-filter: $blur;
  backdrop-filter: $blur;
}

// Dark
$dark: #1e1c21;
.c-dark {
  color: $dark;
}
.bg-dark {
  background: $dark !important;
}
.bg-dark-gradient {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba($dark,0) 0%, rgba($dark,0.15) 50%, rgba($dark,0.5) 100%);
}

// Text
$txt: #3c3843;
$txt_disabled: rgba(#3c3843, 0.25);
.c-txt {
  color: $txt;
}
.bg-txt {
  background: $txt;
}
.bg-txt-05 {
  background: rgba($txt, 0.05);
}
.bg-txt-15 {
  background: rgba($txt, 0.15);
}
.bg-txt-12 {
  background: rgba($txt, 0.05);
}

// Primary
$prime: #009ffd; // #58b0f9;
$prime_gradient_webkit: #009ffd; // -webkit-linear-gradient(to right, #5b86e5, #36d1dc);
$prime_gradient: #009ffd; // linear-gradient(to right, #5b86e5, #36d1dc);

$prime_hover: rgba(#009ffd, 0.75);
$prime_gradient_webkit_hover: rgba(#009ffd, 0.75);
$prime_gradient_hover: rgba(#009ffd, 0.75);

.c-prime {
  color: $prime;
}
.bg-prime {
  background: $prime;
  background: $prime_gradient_webkit;
  background: $prime_gradient;
}
.bg-prime-50 {
  background: rgba($prime, 0.5);
  // -webkit-backdrop-filter: $blur;
  // backdrop-filter: $blur;
}
.bg-prime-10 {
  background: rgba($prime, 0.1);
}

// ¥¥ Hover, Disabled, ... States!!

// Secondary
$second: #ffa400; // #f9a258;
$second_gradient_webkit: #ffa400;
$second_gradient: #ffa400;

// Sustainability
$sustainability_green: #43B02A;
$sustainability_bg: #f7f6ee;

.c-sustainability {
  color: $sustainability_green;
}
.bg-sustainability {
  background: $sustainability_bg;
}

// Border
$border: 0.15em solid $prime;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

.c {
  &-second {
    color: $second;
  }
}

.c-danger {
  color: $danger !important;
}
