.Scroller-bodystyles {
    > div {
        padding-left: $padding_wrapper;

        > div:first-of-type {
            padding-left: 15px;
        }
      
        > div:last-of-type {
            padding-right: $padding_wrapper;
            opacity: 0;
            visibility: hidden;
            margin: 0;
            color: transparent;
        }
    }

    &-elem {
        margin-right: 3em;

        > button {
            > div {
            position: relative;

            > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                height: 1em;
                color: $prime;
                display: none;
            }

            > img {
                height: 2.5em;
            }
            }

            &:hover > div > svg {
            display: inline;
            }

            > p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            }
        }

        &-active {
        .spinner {
            height: 2em;
            margin: 0.25em 0;
            color: $txt;
            position: initial;
        }

        > button > div > svg {
            display: inline;
        }
        }
    }
}