// https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/

.Select {
  position: relative;

  &-w-100 {
    width: 100%;

    button {
      width: 100%;
    }
  }

  button {
    padding: $padding;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: start;

    @media (max-width: $sm) {
      padding: $padding_sm;
    }
  }

  &-trigger {
    width: 100%;
    border: $border;
    color: rgba($txt, 0.25);
    border-color: rgba($txt, 0.25);
    box-shadow: none;

    &-arrow {
      margin-left: auto;
    }

    &:hover {
      box-shadow: 0 0 0 0.15em $prime_hover;
      
      .Select-trigger-arrow {
        transform: scale(1.2); // rotate(180deg);
      }
    }

    &-active {
      border-color: $txt;

      &:hover {
        box-shadow: none;
      }
    }

    &-selected {
      color: $txt;
    }
  }

  &-dropdown {
    position: absolute;
    top: 3em;
    left: 0;
    right: 0;
    border: $border;
    border-top: none;
    border-radius: 0 0 $border_radius $border_radius;
    max-height: 16em;
    overflow-y: scroll;
    border-color: rgba($txt, 0.25);
    background: $base;
    display: none;
    z-index: 100;

    &-active {
      display: inline;
      border-color: $txt;
    }

    ul {
      width: 100%;

      li {
        width: 100%;

        label {
          width: 100%;

          button {
            width: 100%;
            border: none;
            color: rgba($txt, 0.5);
            box-shadow: none;
            pointer-events: none;
            border-radius: 0;
          }

          &:hover {
            button {
              color: rgba($txt, 0.75);
            }
          }

          input:checked ~ button {
            color: rgba($txt, 0.75);
            background: rgba($txt, 0.1);
          }
        }
      }
    }
  }

  &-base {
    .Select {
      &-trigger {
        color: rgba($base, 0.25);
        border-color: rgba($base, 0.25);
        &-active {
          border-color: $base;
        }
        &-selected {
          color: $base;
        }
      }

      &-dropdown {
        border-color: rgba($base, 0.25);
        background: $dark;
        &-active {
          border-color: $base;
        }
        ul li label {
          button {
            color: rgba($base, 0.5);
          }
          &:hover {
            button {
              color: rgba($base, 0.75);
            }
          }
          input:checked ~ button {
            color: rgba($base, 0.75);
            background: rgba($base, 0.1);
          }
        }
      }
    }
  }

  &-txt {
    .Select {
      &-trigger {
        color: $txt;
        border-color: $txt;
        &-active {
          border-color: $txt;
        }
        &-selected {
          color: $txt;
        }
      }

      &-dropdown {
        border-color: $txt;
        background: $base;
        &-active {
          border-color: $txt;
        }
        ul li label {
          button {
            color: $txt;
          }
          &:hover {
            button {
              color: rgba($txt, 0.75);
              background: rgba($txt, 0.1);
            }
          }
          input:checked ~ button {
            color: rgba($txt, 0.75);
            background: rgba($txt, 0.1);
          }
        }
      }
    }
  }

  &-sm {
    button {
      padding: $padding_sm;
    }

    .Select-dropdown {
      top: 2.8em;
    }
  }

  &-regular {
    button {
      font-weight: 400;
    }
  }

  &-dashed {
    > * {
      border-style: dashed;
    }

    .Select-trigger-active,
    .Select-dropdown-active {
      border-style: solid;
    }
  }

  &-minimal {
    button {
      padding: 0;
      border: none;
      border-radius: 0;

      &:hover {
        box-shadow: none;
      }
    }

    .Select-dropdown {
      top: 2em;
      border: none;
      box-shadow: $box_shadow;
      border-radius: $border_radius;

      button {
        padding: $padding;
      }
    }
  }
}
