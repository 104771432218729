.Notify {
  position: fixed;
  top: 5em;
  left: 50%;
  transform: translateX(-50%);
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  > div {
    position: relative;
    background-color: $prime;
    border-radius: $border_radius;

    a {
      p {
        color: $base;
        padding: $padding;
      }
    }

    button {
      position: absolute;
      padding: 0.35em;
      border-radius: 100%;
      background-color: $base;
      border: none;
      top: -0.4em;
      right: -0.4em;
      color: $prime;
      z-index: 105;

      &:hover {
        box-shadow: none;
      }

      svg {
        margin: 0;
        height: 0.54em;
      }
    }
  }

  &-hidden {
    display: none;
  }
}
