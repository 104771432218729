.Footer {
  background-color: $dark;
  color: $base;
  padding: 3em 0;

  a {
    color: $base;

    > * {
      opacity: 0.5;
    }
  }

  li {
    white-space: nowrap;
  }

  svg {
    height: 2em;
  }

  svg:last-of-type {
    margin-right: 0;
  }

  /* &-legal,
  &-offer,
  &-help {
    @media (max-width: $lg) {
      display: none;
    }
  } */
}
