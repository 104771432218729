.Filter {
  &-trigger {
    > button svg {
      height: 2em;
    }
  }

  &:hover {
    .Filter-trigger {
      color: $prime;
      cursor: pointer;

      > button {
        color: $prime;
      }
    }
  }

  &-popup {
    display: none;

    &-outside {
      position: fixed;
      top: 4em;
      right: 30em;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba($dark, 0.1);
      // -webkit-backdrop-filter: blur(0.1em);
      // backdrop-filter: blur(0.1em);
      z-index: 100;
      cursor: pointer;

      @media (max-width: $sm) {
        right: 0;
      }
    }

    &-inner {
      position: fixed;
      top: 4em;
      right: 0;
      bottom: 0;
      width: 30em;
      background: $base;
      box-shadow: $box_shadow;
      padding: 2em;
      box-sizing: border-box;
      overflow-y: scroll;
      z-index: 101;

      @media (max-width: $sm) {
        width: auto;
        left: 0;
      }

      &-feed {
        margin: 0 -0.5em;
        display: flex;
        flex-wrap: wrap;

        &-item {
          width: calc((100% / 3) - 1em);
          box-sizing: border-box;
          margin: 0 0.5em;
          margin-bottom: 1em;

          @media (max-width: $sm) {
            width: calc((100% / 2) - 1em);
          }

          > button {
            width: 100%;
            background-color: rgba($prime, 0.1);
            border-radius: $border_radius;
            padding: 1em;

            > div {
              position: relative;
    
              > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                height: 1em;
                color: $prime;
                display: none;
              }

              > img {
                height: 2em;
              }
            }

            &:hover > div > svg {
              display: inline;
            }

            > p {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &-active > button > div > svg {
            display: inline;
          }
        }
      }
    }

    &-visible {
      display: inline;
    }

    &-close svg {
      height: 1.4em;
    }
  }

  &-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 30em;
    height: 4em;
    box-shadow: $box_shadow;
    background: $base;
    padding: 2em;
    box-sizing: border-box;
    z-index: 101;

    @media (max-width: $sm) {
      width: 100%;
      left: 0;
    }
  }
}
