.Feed {
  &-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em;
  }

  &-pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5em;

    button {
      padding: $padding_sm;
      background: transparent;
      border-color: transparent;
      color: rgba($txt, 0.5);

      svg {
        margin: 0;
      }

      @media (max-width: $sm) {
        padding: 0.75em;
      }

      &:hover {
        border-color: rgba($txt, 0.75);
        box-shadow: none;
        color: rgba($txt, 0.75);

        @media (max-width: $sm) {
          border: none !important;
          border-color: none !important;
        }
      }
    }

    &-page-active {
      border-color: $prime !important;
      color: $prime !important;

      @media (max-width: $sm) {
        border: none !important;
        border-color: none !important;
      }
    }
  }
}
