.Chatbot {
    position: fixed;
    bottom: 2em;
    right: 2em;
    z-index: 100;

    @media (max-width: $med) {
        bottom: 1em;
        right: 1em;
    }

    &-chat {
        position: relative;
        z-index: 101;

        &-outer {
            position: absolute;
            bottom: -4em;
            right: 0;
            width: 24em;
            max-width: calc(100vw - 2em);
            background-color: $base;
            border-radius: $border_radius;
            box-shadow: $box_shadow;

            &-header {
                display: flex;
                align-items: center;
                padding: 1em 0 1em 2em;
                position: relative;

                img {
                    height: 3em;
                    border-radius: 100%;
                    margin-right: 1em; 
                }

                &-online {
                    position: absolute;
                    width: 0.5em;
                    height: 0.5em;
                    background-color: rgb(105,222,64);
                    border-radius: 100%;
                    bottom: 1.4em;
                    left: 4.4em;
                }

                p {
                    color: rgba($txt, 0.5);
                }

                button {
                    background: none;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    
                    svg {
                        color: rgba($txt, 0.75);
                    }
                }
            }

            textarea {
                width: 100%;
                box-sizing: border-box;
                border: none;
                box-shadow: none;
            }

            input {
                width: 100%;
                box-sizing: border-box;
                border: none;
                box-shadow: none;
            }
        }

        &-inner {
            padding: 1em 2em 2em 2em;
            background-color: rgba($txt, 0.12);
            max-height: 40vh;
            overflow-y: scroll;
        }

        &-message {
            margin-bottom: 0.5em;

            &-avatar {
                display: flex;
                align-items: center;
                margin-bottom: 0.5em;
                
                img {
                    height: 1.4em;
                    border-radius: 100%;
                    margin-right: 0.5em;
                }
            }

            &-txt {
                padding: 1em;
                background-color: $base;
                border-top-right-radius: $border_radius;
                border-bottom-right-radius: $border_radius;
                max-width: 16em;
                margin-left: auto;
                position: relative;

                &-check {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 0.5em;
                    right: 0.5em;

                    svg {
                        height: 0.5em;

                        &:last-of-type {
                            margin-left: -0.2em;
                        }
                    }
                }

                &-typing {
                    display: flex;
                    align-items: center;
                    position: relative;
                    height: 1em;
    
                    div {
                        width: 0.4em;
                        height: 0.4em;
                        background-color: rgba($txt, 0.75);
                        border-radius: 100%;
                        margin-right: 0.25em;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        &:nth-child(1) {
                            animation: firstDot 1.5s infinite;
                        }

                        &:nth-child(2) {
                            left: 2em;
                            animation: secondDot 1.5s infinite;
                        }

                        &:nth-child(3) {
                            left: 3em;
                            animation: thirdDot 1.5s infinite;
                        }
                    }

                    @keyframes firstDot {
                        0%   {top: 50%;}
                        25%  {top: 45%;}
                        75%  {top: 55%;}
                        100% {top: 50%;}
                    }

                    @keyframes secondDot {
                        0%   {top: 55%;}
                        50%  {top: 45%;}
                        100% {top: 55%;}
                    }

                    @keyframes thirdDot {
                        0%   {top: 50%;}
                        25%  {top: 55%;}
                        75%  {top: 45%;}
                        100% {top: 50%;}
                    }
                }
            }
        }

        &-incoming {
            .Chatbot-chat-message:nth-child(2) .Chatbot-chat-message-txt {
                border-top-left-radius: $border_radius;
            }
            .Chatbot-chat-message:last-of-type .Chatbot-chat-message-txt {
                border-bottom-left-radius: $border_radius;
            }
        }

        &-outgoing {
            margin-top: 1.5em;

            .Chatbot-chat-message {
                &:first-of-type .Chatbot-chat-message-txt {
                    border-top-left-radius: $border_radius;
                }

                &-txt {
                    background-color: $prime;
                    color: $base;
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        &-send {
            width: auto;
            background-color: transparent;
            border: none;
            padding: 0;
            height: 3.5em;
            width: 4em;

            &:hover {
                box-shadow: none;
            }

            &:active svg {
                transition: all 0.2s;
                transform: scale(0.8);
            }

            svg {
                color: rgba($txt, 0.5);
                height: 1.5em;
                max-height: 1.5em;
                max-width: 1.5em;
                margin: 0;
            }
        }
    }

    &-trigger {
        height: 4em;
        width: 4em;
        background-color: $prime;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;

        svg {
            margin-top: 0.1em;
            color: $base;
            height: 2em;
        }

        &:hover {
            cursor: pointer;
        }
    }
}