.Cover {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: $base;

  &-inner {
    width: 100%;
    padding-bottom: calc(75vh - 4em);

    &-aspect {
      padding-bottom: Min(56.25%, calc(75vh - 4em));
    }
  }

  &-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-shrink: 0;
  }

  .Wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  &-backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  &-darken {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($dark, 0.1);
  }

  &-new {
    position: relative;

    &-content {
      z-index: 2;
    }

    &-gradient {
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
    }

    &-img {
      position: absolute;
      right: -10em;
      bottom: -10em;
      width: 80%;

      &-backdorp {
        z-index: -1;
      }
    }
  }

  &-img-contain {
    object-fit: contain;
  }
}
