table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    tr {
        th,
        td {
            text-align: left;
            vertical-align: top;
            padding-bottom: 1em;

            svg {
                height: 1em;
            }
        }

        th {
            table-layout: fixed;
            width: 25%;
            max-width: calc(25vw - ((100vw - 1400px) / 2));
            white-space: nowrap;

            @media (max-width: $xlg) {
                width: 33.33%;
                max-width: calc(33.33vw - ((100vw - 1400px) / 2));

                &:nth-child(4) {
                    display: none;
                }
            }

            svg {
                width: 1em;
                max-height: 1em;
                margin-right: 0.5em;
            }

            .Infobox {
                white-space: normal;
            }
        }
        
        td {
            table-layout: fixed;
            width: 25%;
            max-width: calc(25vw - ((100vw - 1400px) / 2));
            padding-left: 1em;

            @media (max-width: $xlg) {
                width: 33.33%;
                max-width: calc(33.33vw - ((100vw - 1400px) / 2));

                &:nth-child(4) {
                    display: none;
                }
            }
        }

        &:last-of-type {
            th,
            td {
                padding-bottom: 0;
            }
        }

        .th-center,
        .td-center {
            vertical-align: middle;
        }

        .td-100 {
            width: 100%;
        }
    }
}

.th-10em {
    th {
        width: 10em;
        max-width: 50%;
    }
}

.table-mobile {
    width: 100%;
    // max-width: 40em;

    &-elem {
        width: 100%;

        &-trigger {
            width: 100% !important;
            display: flex !important;
            flex-wrap: nowrap !important;
            box-sizing: border-box !important;
            justify-content: flex-start !important;
            padding: 1em 0 !important;
            text-align: start !important;

            p:first-of-type {
                width: 100%;
            }

            p:last-of-type {
                opacity: 0.5;
            }

            svg {
                width: 1em !important;
                max-height: 1.2em !important;
                margin-right: 0.5em !important;
            }
        }

        &-content-item {
            &:last-of-type {
                margin-bottom: 1.5em;
            }
        }
    }
}