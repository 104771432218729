.Element {
    &-excisting {
        margin-bottom: 1em;
    }

    &-create {
        width: 100%;
        // opacity: 0.5;
        position: relative;
        box-sizing: border-box;
    
        &-trigger {
            width: 100%;
            border: $border;
            border-color: $txt;
            padding: $padding_sm;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border_radius;
            border-style: dashed;
            box-sizing: border-box;
            
            &-icon {
                width: 1em;
                height: 1em;
                border: $border;
                border-color: $txt;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.5em;
                border-radius: 100%;
    
                > * {
                    margin-bottom: 0.15em;
                }
            }
    
            &:hover {
                cursor: pointer;
            }
        }
    
        // &:hover {
        //     opacity: 0.75;
        // }
    
        &-dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 3.5em;
            left: 0;
            right: 0;
            z-index: 100;
            background: $base;
    
            button {
               width: 100%;
    
                &:first-of-type {
                    margin-right: 0.25em;
                }
                &:last-of-type {
                    margin-left: 0.25em;
                }
            }
        }
    }
}