.absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  &-bottom {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
  }

  &-left {
    left: 0;
    transform: translateX(-25%) translateY(-55%);
  }

  &-left-25 {
    transform: translateX(-25%) translateY(-60%);
  }
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.z--1 {
  z-index: -1;
}
.z-1 {
  z-index: 1;
}
