.Gallery  {
    width: 100%;
    overflow: hidden;

    &-inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 50vh;
    }

    &-slide {
        width: 100%;
        max-height: 100%;
        padding-bottom: 56.25%;

        &-hidden {
            display: none;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        img {
            height: 100%;
            object-fit: contain;
            background: black;
            width: 100%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-thumbnails {
        margin-top: 1em;
    }

    &-thumbnail {
        width: 10em;
        padding-bottom: calc(10em * 0.5625);
        position: relative;

        @media (max-width: $med) {
            width: 7em;
            padding-bottom: calc(7em * 0.5625);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 2em;
            color: $base;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($txt, 0.15);
        border-radius: 100%;
        padding: 1em;
        -webkit-backdrop-filter: $blur;
        backdrop-filter: $blur;
        z-index: 2;

        &-inner {
            position: relative;

            svg {
                height: 1em;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: rgba($base, 0.5);
            }
        }

        &:hover {
            svg {
                color: rgba($base, 0.75);
            }
        }
    }

    &-prev {
        left: 1em;
    }

    &-next {
        right: 1em;
    }
}