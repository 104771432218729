.op {
  &-25 {
    opacity: 0.25;
  }
  &-50 {
    opacity: 0.5;
  }
  &-75 {
    opacity: 0.75;
  }
  &-100 {
    opacity: 1;
  }
}
