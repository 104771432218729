.w {
  &-a { width: auto; }

  &-20 { width: 20%; }
  &-25 { width: 25%; }
  &-40 { width: 40%; }
  &-50 { width: 50%; }
  &-60 { width: 60%; }
  &-75 { width: 75%; }
  &-100 { width: 100%; }

  &-0-2em { width: 0.2em; }
  &-0-5em { width: 0.5em; }
  &-1em { width: 1em; }
  &-1-2em { width: 1.2em; }
  &-1-5em { width: 1.5em; }
  &-2em { width: 2em; }
  &-2-5em { width: 2.5em; }
  &-3em { width: 3em; }
  &-4em { width: 4em; }
  &-5em { width: 5em; }
  &-6em { width: 6em; }
  &-7em { width: 7em; }
  &-8em { width: 8em; }
  &-9em { width: 9em; }
  &-10em { width: 10em; }
  &-12em { width: 12em; }
  &-20em { width: 20em; }

  &min-20em {
    min-width: 20em;
  }

  &max-8em {
    max-width: 8em;
  }
  &max-40em {
    max-width: 40em;
  }

  &-border-box {
    box-sizing: border-box;
  }
}
