.Rating {
    display: flex;

    &-block {
        width: 2em;
        height: 0.5em;
        background: rgba($txt, 0.15);
        border-radius: $border_radius;
        margin-right: 0.25em;

        &-active {
            background: $prime;
        }
    }

    &-star {
        height: 1.5em;
        color: rgba($txt, 0.15);
        margin-right: 0.25em;

        &-active {
            color: $prime;
        }
    }

    input {
        display: none;
    }

    input:nth-of-type(1):checked ~ label:nth-of-type(1) {
        .Rating-block {
            background: $prime;
        }
        .Rating-star {
            color: $prime;
        }
    }
    input:nth-of-type(2):checked ~ label:nth-of-type(-n+2) {
        .Rating-block {
            background: $prime;
        }
        .Rating-star {
            color: $prime;
        }
    }
    input:nth-of-type(3):checked ~ label:nth-of-type(-n+3) {
        .Rating-block {
            background: $prime;
        }
        .Rating-star {
            color: $prime;
        }
    }
    input:nth-of-type(4):checked ~ label:nth-of-type(-n+4) {
        .Rating-block {
            background: $prime;
        }
        .Rating-star {
            color: $prime;
        }
    }
    input:nth-of-type(5):checked ~ label:nth-of-type(-n+5) {
        .Rating-block {
            background: $prime;
        }
        .Rating-star {
            color: $prime;
        }
    }
}