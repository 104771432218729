.Carousel {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $txt;
  max-height: 50vh;
  overflow: hidden;

  &-inner {
    width: 100%;
    max-height: 100%;
    padding-bottom: 56.25%;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;

    &-hidden {
      display: none;
    }

    span {
      width: 100%;
      display: table;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &-img-100vw {
      width: 100%;
    }
  }

  &-prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) scale(0.5);
    outline: none;
    box-shadow: none;
    border-radius: 100%;
    padding: 1em 1.3em;
    border: none;
    background-color: rgba($txt, 0.25);

    svg {
      height: 1.5em;
      margin: 0;
      margin-right: 0.25em;
      color: $base;
    }

    &:hover {
      box-shadow: none;
      background-color: rgba($txt, 0.5);
    }
  }
  &-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) scale(0.5);
    outline: none;
    box-shadow: none;
    border-radius: 100%;
    padding: 1em 1.3em;
    border: none;
    background-color: rgba($txt, 0.25);

    svg {
      height: 1.5em;
      margin: 0;
      margin-left: 0.25em;
      color: $base;
    }

    &:hover {
      box-shadow: none;
      background-color: rgba($txt, 0.5);
    }
  }

  // &:hover {
  //   .Carousel-next {
  //     display: block;
  //   }
  // }
}



/*

&-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

*/