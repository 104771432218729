.Nav {
  ul {
    li {
      display: flex;
      align-items: center;

      a {
        > * {
          display: flex;
          align-items: center;
        }
      }

      img,
      svg {
        max-width: 2em;
        max-height: 2em;
        margin-right: 0.5em;
      }
    }
  }

  &-horizontal {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-end ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &-5 {
    li {
      width: 20%;

      @media (max-width: $xlg) {
        width: 25%;
      }

      @media (max-width: $lg) {
        width: 33.33%;
      }

      @media (max-width: $med) {
        width: 50%;
      }
    }
  }
}
