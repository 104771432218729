.Stripe {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    span {
        width: 20%; 
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 10em;
    }
}