.Newsletter {
  position: relative;
  text-align: center;
  overflow: hidden;

  > div div {
    > input {
      min-width: 25%;
    }

    > img {
      height: 7em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      z-index: -1;
    }

    > img:first-of-type {
      right: 75%;
    }
    > img:last-of-type {
      left: 75%;
    }
  }
}
