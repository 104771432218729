.flex {
  display: flex;
  align-items: center;

  &-ai {
    &-e {
      align-items: flex-end;
    }
    &-s {
      align-items: start;
    }
  }

  &-jc {
    justify-content: center;

    &-e {
      justify-content: flex-end;
    }

    &-sb {
      justify-content: space-between;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-4 {
    > div {
      width: 25%;
      margin-bottom: 2em;

      @media (max-width: $lg) {
        width: 33.33%;
      }

      @media (max-width: $med) {
        width: 50%;
      }
    }
  }

  &-md {
    @media (max-width: $med) {
      display: flex;
    }
  }

  &-noflex-md {
    @media (max-width: $med) {
      display: inline;
    }
  }

  &-noshrink {
    flex-shrink: none;
  }
}
