.Banner {
  width: 100%;
  border-radius: $border_radius;
  padding: $padding;
  color: $base;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    opacity: 1;
    width: 100%;
    z-index: -1;
  }

  &-fixed {
    position: fixed;
    z-index: 150;
  }

  &-content {
    z-index: 2;
  }

  &-darken {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba($dark, 0.5);
    z-index: -1;
  }
}
