// Breakpoints
$sm: 576px !default;
$med: 768px !default;
$lg: 992px !default;
$xlg: 1200px !default;
$xxlg: 1400px !default;

.hide {
  display: none !important;

  &-sm {
    @media (max-width: $sm) {
      display: none !important;
    }
  }
  &-med {
    @media (max-width: $med) {
      display: none !important;
    }
  }
  &-lg {
    @media (max-width: $lg) {
      display: none !important;
    }
  }
  &-xlg {
    @media (max-width: $xlg) {
      display: none !important;
    }
  }
  &-xxlg {
    @media (max-width: $xxlg) {
      display: none !important;
    }
  }
}

.display {
  display: inline !important;

  &-sm {
    @media (max-width: $sm) {
      display: inline !important;
    }
  }
  &-med {
    @media (max-width: $med) {
      display: inline !important;
    }
  }
  &-lg {
    @media (max-width: $lg) {
      display: inline !important;
    }
  }
  &-xlg {
    @media (max-width: $xlg) {
      display: inline !important;
    }
  }
  &-xxlg {
    @media (max-width: $xxlg) {
      display: inline !important;
    }
  }
}

// Base
$base: #ffffff !default;
$base_hover: rgba(#ffffff, 0.75) !default;
$base_disabled: rgba(#ffffff, 0.25) !default;
.c-base {
  color: $base;
}
.bg-base {
  background: $base;
}

// Dark
$dark: #282828 !default;
.c-dark {
  color: $dark;
}
.bg-dark {
  background: $dark;
}
.bg-black {
  background: black;
}

// Text
$txt: #000000 !default;
$txt_hover: rgba(#000000, 0.75) !default;
$txt_disabled: rgba(#000000, 0.25) !default;
.c-txt {
  color: $txt;
}
.bg-txt {
  background: $txt;
}
.bg-txt-15 {
  background: rgba($txt, 0.15);
}
.bg-txt-12 {
  background: rgba($txt, 0.12);
}

// Primary
$prime: #000000 !default;
$prime_gradient_webkit: #000000 !default;
$prime_gradient: #000000 !default;
.c-prime {
  color: $prime;
}
.bg-prime {
  background: $prime;
  background: $prime_gradient_webkit;
  background: $prime_gradient;
}

$prime_hover: rgba(#000000, 0.75) !default;
$prime_gradient_webkit_hover: rgba(#000000, 0.75) !default;
$prime_gradient_hover: rgba(#000000, 0.75) !default;

$prime_disabled: rgba(#000000, 0.25) !default;
$prime_gradient_webkit_disabled: rgba(#000000, 0.25) !default;
$prime_gradient_disabled: rgba(#000000, 0.25) !default;

// Danger
$danger: red !default;
$danger_gradient_webkit: red !default;
$danger_gradient: red !default;
.c-danger {
  color: $danger;
}
.c-danger-placeholder::placeholder {
  color: $danger;
}
.bg-danger {
  background: $danger;
}

$danger_hover: rgba(red, 0.75) !default;
$danger_gradient_webkit_hover: rgba(red, 0.75) !default;
$danger_gradient_hover: rgba(red, 0.75) !default;

$danger_disabled: rgba(red, 0.25) !default;
$danger_gradient_webkit_disabled: rgba(red, 0.25) !default;
$danger_gradient_disabled: rgba(red, 0.25) !default;

$filter_base: invert(1);

// Padding
$padding: 1.15em 1.9em !default;
$padding_sm: 0.9em 1.5em !default;
$padding_wrapper: calc((100vw - 1400px) / 2) !default;
.padding {
  padding: $padding !important;
}
.padding-sm {
  padding: $padding_sm !important;
}
.padding-1-5em {
  padding: 1.5em;
}

// Borders
$border: 0.15em solid $txt !default;
$border_radius: 0.5em !default;
.border-radius {
  border-radius: $border_radius;
}
$box_shadow: 0 0 0.6em rgba($txt, 0.5);

.bg-transparent {
  background: transparent;
}
