.Popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: $blur;
    -webkit-backdrop-filter: $blur;
    z-index: 150;

    &-close {
        position: absolute;
        right: 2em;
        top: 2em;

        svg {
            height: 1.2em;
            color: rgba($txt, 0.5);
        }
    }

    &-inner {
        background: $base;
        border-radius: $border_radius;
        box-shadow: $box_shadow;
        width: 100%;
        height: calc(100vh - 6em);
        margin-top: 3em;
        overflow-y: scroll;
        padding: 2em;
        box-sizing: border-box;
        position: relative;
    }
}