.h {
  &-50 {
    height: 50%;
  }
  &-100 {
    height: 100%;
  }

  &-35vh {
    height: 35vh;
  }
  &-45vh {
    height: 45vh;
  }
  &-55vh {
    height: 55vh;
  }
  &-100vh {
    height: 100vh;

    &-header {
      height: calc(100vh - 4em);
    }
  }

  &-max {
    &-10em {
      max-height: 10em;
    }
    &-100 {
      max-height: 100%;
    }
  }

  &-min {
    &-50vh {
      min-height: 50vh;
    }
    &-100vh {
      min-height: 100vh;
    }
  }

  &-0-5em {
    height: 0.5em;
  }
  &-0-75em {
    height: 0.75em;
  }
  &-1em {
    height: 1em;
  }
  &-1-2em {
    height: 1.2em;
  }
  &-1-5em {
    height: 1.5em;
  }
  &-2em {
    height: 2em;
  }
  &-2-5em {
    height: 2.5em;
  }
  &-3em {
    height: 3em;
  }
  &-3-5em {
    height: 3.5em;
  }
  &-3-75em {
    height: 3.7em; // Used on compare page loading spinners!!
  }
  &-4em {
    height: 4em;
  }
  &-5em {
    height: 5em;
  }
  &-10em {
    height: 10em;
  }
  &-15em {
    height: 15em;
  }
}
