.CoverNew {
    width: 100%;
    height: calc(75vh - 4em);
    position: relative;
    display: flex;
    align-items: center;
    // background: $prime;
    color: $base;

    @media (max-width: $lg) {
        background: $prime;
    }

    &-search {
        display: flex;
        justify-content: center;
        margin-bottom: 3em;
        display: none;

        &-elem {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $base;
            border-radius: 15em;
            padding-right: 0.5em;
            box-shadow: $box_shadow;

            input {
                border: none;
                background: transparent;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
                
                &:hover {
                    box-shadow: none;
                }
            }

            &-submit {
                border-radius: 100%;
                width: 2em;
                height: 2em;
                background: $prime;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 1em;
                    color: $base;
                }
            }
        }
    }

    &-headers {
        max-width: 75%;

        @media (max-width: $med) {
            max-width: none;
        }

        p {
            font-size: 1.4em;

            @media (max-width: $med) {
                font-size: 1em;
            }
        }
    }

    &-btns {
        display: flex;

        @media (max-width: $med) {
            display: block;
        }

        div {
            margin: none;

            &:last-of-type {
                margin-left: 1em;

                @media (max-width: $med) {
                    margin-left: 0;
                    margin-top: 0.5em;
                }
            }
        }
    }

    // .Wrapper {
    //     position: relative;
    // }

    &-img {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        display: flex;
        background: $prime;
        z-index: -1;

        @media (max-width: $lg) {
            display: none;
        }

        &-left {
            width: 45%;
            color: transparent;
        }
        &-right {
            width: 55%;
        }

        /* position: absolute;
        bottom: -45%;
        right: -12%;
        max-width: 75%;
        // z-index: -1; */
    }
}