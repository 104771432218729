.Scroller {
    width: 100%;
    overflow: hidden;
    position: relative;

    &-inner {
        display: flex;
        align-items: start;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        cursor: grab;

        &::-webkit-scrollbar {
            display: none;
        }

        &:active {
            cursor: grabbing;
        }

        > * {
            margin-right: 1em;
        }
    }

    &-nopadding {
        padding: 0;
        margin: 0;

        > div {
            padding: 0 !important;

            > div:first-of-type {
                padding-left: 0 !important;
            }
          
            > div:last-of-type {
                padding-right: 0 !important;
            }
        }
    }

    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($txt, 0.15);
        border-radius: 100%;
        padding: 1em;
        -webkit-backdrop-filter: $blur;
        backdrop-filter: $blur;
        z-index: 2;

        &-inner {
            position: relative;

            svg {
                height: 1em;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: rgba($base, 0.5);
            }
        }

        &:hover {
            svg {
                color: rgba($base, 0.75);
            }
        }
    }

    &-prev {
        left: 1em;
    }

    &-next {
        right: 1em;
    }
}

.ScrollerItem {
    position: relative;

    &-info {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
    }
}