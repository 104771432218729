.ReviewItem {
  width: calc(33.33% - 2em);
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 1em;
  padding: 1.5em;
  padding-top: 0;
  text-align: center;
  position: relative;

  &-info {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
  }

  &-editors {
    position: absolute;
    top: 1.5em;
    right: 3.5em;
    display: flex;
    z-index: 2;

    &-editor {
      height: 2.5em;
      width: 2.5em;
      border-radius: 100%;
      margin-right: -2em;
      border: $border;
      border-color: $base;
      position: relative;

      @media (max-width: $med) {
        height: 2em;
        width: 2em;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }

      &-popup {
        position: absolute;
        top: 2.8em;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: $box_shadow;
        padding: 0.5em;
        border-radius: $border_radius;
        background: $base;
        white-space: nowrap;
        display: none;
      }

      &:hover {
        cursor: pointer;

        .ReviewItem-editors-editor-popup {
          display: inline;
        }
      }
    }
  }

  &-gallery {
    position: relative;

    &-aspect {
      padding-bottom: 56.25%;
    }

    &-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    &-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  &-data {
    &-description{
      min-height: 4.75em;
    }

    &-pro {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0.5em;

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  > a button {
    width: 11em;
  }

  &:hover {
    box-shadow: $box_shadow;

    > a:last-of-type {
      display: inline;
    }
  }

  @media (max-width: $lg) {
    width: calc(50% - 2em);
  }

  @media (max-width: $med) {
    margin-bottom: 2em;
    padding-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }

  @media (max-width: $sm) {
    width: calc(100% - 2em);
  }
}