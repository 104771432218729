.Cookies {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: 200;
    padding-bottom: 1em;
    backdrop-filter: $blur;
    -webkit-backdrop-filter: $blur;
    display: flex;
    align-items: flex-end;

    > .Wrapper > div {
        padding: 2em;
        box-shadow: 0 0 0.6em rgba($txt, 0.5);
        border-radius: $border_radius;
        background-color: $base;
    }
}