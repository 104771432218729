.Header {
  height: 4em;
  max-width: 100%;

  > div {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 4em;
    background: $prime;
    background: $prime_gradient_webkit;
    background: $prime_gradient;
    display: flex;
    align-items: center;
    z-index: 100;

    > .Wrapper > div {
      position: relative;

      a {
        color: $base;

        &:active {
          svg {
            transform: scale(0.95);
          }
        }
      }

      .Header-nav {
        &-mobile {
          svg {
            height: 1.2em;
            margin: 0;
          }
        }

        &-offer {
          width: 100%;

          a {
            padding: 1.4em 0;
            margin-left: 2.8em;

            // &:first-of-type {
            //   margin-left: 2.8em; 
            // }
          }
          
          .Dropdown a {
            margin-left: 0;
          }
        }

        &-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
  
          img,
          svg {
            height: 3em;
            max-width: 3em;
            max-height: 3em;
            margin: 0;
          }
        }

        &-compare {
          width: 100%; 
        }

        &-profile {
          position: relative;

          &-trigger {
            color: $base;
            border-radius: 10em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.25em;
            width: 3.5em;
            margin-top: 1.4em;
            margin-bottom: 1.4em;
            margin-left: 2em;

            &-menu {
              height: 0.75em;
              margin: 0;
              padding: 0;
              margin-left: 0.25em;
            }

            &-user {
              display: flex;
              align-items: center;

              span {
                height: 1.5em;
                width: 1.5em;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
                  object-fit: cover;
                }
              }

              svg {
                height: 1.5em;
                margin: 0;
                padding: 0;
              }
            }
          }

          &-dropdown {
            position: absolute;
            top: 4.4em; // 5.5em;
            right: 0;
            background: $base;
            padding: 0.5em 1em;
            box-shadow: $box_shadow;
            border-radius: $border_radius;
            display: none;

            ul {
              display: block;

              li {
                a, 
                .a {
                  color: $txt;
                  padding: 0.75em 0;
                  white-space: nowrap;
                  margin: 0;
                }

                hr {
                  width: 100%;
                  background: rgba($txt, 0.1);
                  margin: 0.5em 0;
                }
              }
            }
          }

          &:hover {
            cursor: pointer;

            .Header-nav-profile-trigger {
              opacity: 0.75;
              cursor: pointer;
            }

            .Header-nav-profile-dropdown {
              display: block;
            }
          }
        }
      }
    }
  }

  &-btn {
    padding: 1.4em 0;
  }

  &-dark {
    > div {
      background: $dark;
    }
  }

  .triggerBrandsDropdown {
    position: relative;
  }
}
