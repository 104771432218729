.FloatNav {
    position: fixed;
    z-index: 50;
    top: 50%;
    left: 1em;
    transform: translateY(-50%);
    opacity: 0.5;

    &:hover {
        opacity: 1; 
    }

    ul li button div {
        margin: 0.25em 0;
        border-left: $border;
        border-color: $txt;
        color: $txt;
        height: 1.5em;
        display: flex;
        align-items: center;

        h5 {
            opacity: 0;
            visibility: hidden;
            background: rgba($base, 0.5);
            backdrop-filter: $blur;
            -webkit-backdrop-filter: $blur;
            padding: 0.25em 0.5em;
            border-top-right-radius: $border_radius;
            border-bottom-right-radius: $border_radius;
        }

        &:hover {
            h5 {
                opacity: 1;
                visibility: visible;
            }  
        }
    }
}