.Faq {
    &-elem {
        border-bottom: $border;
        border-color: rgba($txt, 0.25);

        button {
            width: 100%;
            text-align: start;
        }

        &-question {
            padding: 1em 0;

            &:hover {
                opacity: 0.75;
            }
        }

        &-answer {
            padding-top: 2em;
            padding-bottom: 3em;
            display: none;
        }

        &-active {
            .Faq-elem-answer {
                display: block;
            }
        }
    }
}