.TestimonialItem {
    width: calc(50% - 2em);
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0 1em;
    padding: 1.5em;
    position: relative;
    background: rgba($prime, 0.1);
    margin-bottom: 1em;

    @media (max-width: $med) {
        width: calc(100% - 2em);
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-verified {
        position: relative;

        &-popup {
            position: absolute;
            top: -2.3em;
            left: 0;
            background: rgba($base, 0.5);
            backdrop-filter: $blur;
            -webkit-backdrop-filter: $blur;
            padding: 0.25em;
            box-shadow: $box_shadow;
            border-radius: $border_radius;
            white-space: nowrap;
            display: none;
        }

        &:hover {
            .TestimonialItem-verified-popup {
                display: block;
            }
        }
    }

    &-profile-picture {
        background: $dark; // $base;
        border-radius: 100%;
        width: 3.5em;
        height: 3.5em;
        overflow: hidden;

        @media (max-width: $lg) {
            width: 3em;
            height: 3em;
        }

        @media (max-width: $sm) {
            width: 2.5em;
            height: 2.5em;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-description {
        min-height: 4.75em;
    }
}