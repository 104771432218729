.m {
  &-0 {
    margin: 0 !important;
  }

  &-a {
    margin: auto;
  }

  &x {
    &-a {
      margin: 0 auto;
    }

    &-0-25 {
      margin: 0 0.25em;
    }
    &-0-5 {
      margin: 0 0.5em;
    }
    &-1 {
      margin: 0 1em;
    }
    &-2 {
      margin: 0 2em;
    }
  }

  &y {
    &-0-62 {
      padding: 0.62em 0;
    }
    &-1 {
      margin: 1em 0;
    }
    &-1-5 {
      margin: 1.5em 0;
    }
    &-2 {
      margin: 2em 0;
    }
    &-3 {
      margin: 3em 0;
    }
  }

  &b {
    &-0-05 {
      margin-bottom: 0.05em;
    }
    &-0-1 {
      margin-bottom: 0.1em;
    }
    &-0-15 {
      margin-bottom: 0.15em;
    }
    &-0-2 {
      margin-bottom: 0.2em;
    }
    &-0-25 {
      margin-bottom: 0.25em;
    }
    &-0-3 {
      margin-bottom: 0.3em;
    }
    &-0-35 {
      margin-bottom: 0.35em;
    }
    &-0-4 {
      margin-bottom: 0.4em;
    }
    &-0-45 {
      margin-bottom: 0.45em;
    }
    &-0-5 {
      margin-bottom: 0.5em;
    }
    &-0-75 {
      margin-bottom: 0.75em;
    }
    &-1 {
      margin-bottom: 1em;
    }
    &-1-5 {
      margin-bottom: 1.5em;
    }
    &-2 {
      margin-bottom: 2em;
    }
    &-3 {
      margin-bottom: 3em;
    }
    &-4 {
      margin-bottom: 4em;
    }
    &-5 {
      margin-bottom: 5em;
    }

    &- {
      &-0-5 {
        margin-bottom: -0.5em;
      }
    }
  }

  &t {
    &-0-1 {
      margin-top: 0.1em;
    }
    &-0-15 {
      margin-top: 0.15em;
    }
    &-0-2 {
      margin-top: 0.2em;
    }
    &-0-25 {
      margin-top: 0.25em;
    }
    &-0-3 {
      margin-top: 0.3em;
    }
    &-0-5 {
      margin-top: 0.5em;
    }
    &-1 {
      margin-top: 1em;
    }
    &-1-5 {
      margin-top: 1.5em;
    }
    &-2 {
      margin-top: 2em;
    }
    &-3 {
      margin-top: 3em;
    }
    &-4 {
      margin-top: 4em;
    }
    &-5 {
      margin-top: 5em;
    }

    &- {
      &-0-5 {
        margin-top: -0.5em;
      }
    }
  }

  &r {
    &-0 {
      margin-right: 0 !important;
    }
    &-a {
      margin-right: auto;
    }
    &-0-15 {
      margin-right: 0.15em;
    }
    &-0-2 {
      margin-right: 0.2em;
    }
    &-0-25 {
      margin-right: 0.25em;
    }
    &-0-5 {
      margin-right: 0.5em;
    }
    &-1 {
      margin-right: 1em;
    }
    &-1-5 {
      margin-right: 1.5em;
    }
    &-2 {
      margin-right: 2em;
    }
    &-3 {
      margin-right: 3em;
    }
    &-4 {
      margin-right: 4em;
    }
    &-5 {
      margin-right: 5em;
    }
  }

  &l {
    &-0 {
      margin-left: 0 !important;
    }
    &-a {
      margin-left: auto;
    }
    &-0-15 {
      margin-left: 0.15em;
    }
    &-0-2 {
      margin-left: 0.2em;
    }
    &-0-25 {
      margin-left: 0.25em;
    }
    &-0-5 {
      margin-left: 0.5em;
    }
    &-0-75 {
      margin-left: 0.75em;
    }
    &-1 {
      margin-left: 1em;
    }
    &-1-5 {
      margin-left: 1.5em;
    }
    &-2 {
      margin-left: 2em;
    }
    &-3 {
      margin-left: 3em;
    }
    &-4 {
      margin-left: 4em;
    }
    &-5 {
      margin-left: 5em;
    }
  }

  &t {
    &-0-10 {
      margin-top: 0.1em;
    }
  }
}

.p {
  &-sm {
    padding: $padding_sm;
  }

  &x {
    &-0-5 {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
    &-1 {
      padding-left: 1em;
      padding-right: 1em;
    }
    &-2 {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  &y {
    &-0-5 {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    &-1 {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    &-2 {
      padding-top: 2em;
      padding-bottom: 2em;
    }
    &-3 {
      padding-top: 3em;
      padding-bottom: 3em;
    }
    &-5 {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }

  &-0-5 {
    padding: 0.5em;
  }
  &-1 {
    padding: 1em;
  }
  &-1-5 {
    padding: 1.5em;
  }
  &-2 {
    padding: 2em;
  }

  &-l {
    &-0-5 {
      padding-left: 0.5em;
    }
  }

  &-r {
    &-5 {
      padding-left: 5em;
    }
  }
}
