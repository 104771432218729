// Styles
* {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
  scroll-behavior: smooth;
}

html,
body {
  color: $txt;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.font-400 {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
strong,
.strong,
button,
th,
.font-600 {
  font-weight: 800;
}

h1,
.h1 {
  font-size: 3em;

  @media (max-width: $sm) {
    font-size: 2em;
  }
}
h2,
.h2 {
  font-size: 2em;

  @media (max-width: $sm) {
    font-size: 1.6em;
  }
}
h3,
.h3 {
  font-size: 1.6em;

  @media (max-width: $sm) {
    font-size: 1.4em;
  }
}
h4,
.h4 {
  font-size: 1.2em;

  @media (max-width: $sm) {
    font-size: 1.1em;
  }
}
h5,
.h5 {
  font-size: 1em;
}
h6,
.h6 {
  font-size: 0.6em;
}
.h0 {
  font-size: 3em;
}

button {
  background: $prime;
  background: $prime_gradient_webkit;
  background: $prime_gradient;
  border: $border;
  border-radius: $border_radius;
  color: $base;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $padding;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: $sm) {
    padding: $padding_sm;
  }

  > img,
  > svg {
    height: 1em;
    max-width: 1.2em;
    margin-right: 0.5em;
    object-fit: contain;
    flex-shrink: 0;
  }

  > p {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
  }

  &:hover {
    box-shadow: 0 0 0.6em rgba($txt, 0.5);
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: default;
    box-shadow: none !important;
    opacity: 0.25 !important;
  }
}

a,
.a {
  color: $txt;
  text-decoration: none;

  * {
    transition: all 0.2s;
  }

  &:hover {
    cursor: pointer;

    > * {
      opacity: 0.75 !important;
    }

    .hover-blur {
      opacity: 1 !important;
      background: rgba($prime, 0.75);
      border-color: transparent;
      backdrop-filter: $blur;
      -webkit-backdrop-filter: $blur;
    }
  }

  &:active {
    > * {
      transform: scale(0.95);
    }

    button {
      box-shadow: none;

      &:disabled {
        transform: none;
      }
    }
  }

  &-disabled {
    cursor: default !important;
    pointer-events: none !important;

    > * {
      &:hover {
        opacity: 0.5 !important;
        transform: none !important;
      }
    }
  }

  &-no-hover {
    &:hover {
      > * {
        opacity: 1 !important;
      }
    }
  }

  &-no-active-med {
    @media (max-width: $med) {
      &:active {
        > * {
          transform: none;
        }
      }
    }
  }
}

p,
.p {
  font-weight: 400;
}

img {
  object-fit: cover;
}

.input,
input,
textarea {
  border-radius: $border_radius;
  border: $border;
  border-color: $txt_disabled;
  padding: $padding_sm; // $padding
  color: $txt;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  resize: none;
  background-color: $base;
  box-sizing: border-box;

  @media (max-width: $sm) {
    padding: $padding_sm;
  }

  &::placeholder {
    color: $txt_disabled;
  }

  &:focus {
    box-shadow: 0 0 0 0.15em $prime_hover;
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 0 0.15em $prime_hover;
  }

  &:disabled {
    box-shadow: none;
  }

  // Remove arrows/spinners from inputs.
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &.file {
    border: none;
    padding: 0;

    button {
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
      box-shadow: none;
    }
  }

  &.error {
    border-color: $danger;
  }

  &-header {
    font-weight: 700;
  }
}

label,
.label {
  text-align: center;

  * {
    transition: all 0.2s;
  }

  button {
    pointer-events: none;
  }

  input {
    display: none;
  }

  .check {
    opacity: 0;
    visibility: hidden;
    color: transparent;
  }

  .toggle {
    height: 1.5em;
    width: 3em;
    background: rgba($txt, 0.15);
    border-radius: 1em;
    padding: 0.25em;

    div {
      width: 1.5em;
      height: 1.5em;
      background: $base;
      border-radius: 100%;
    }
  }

  &:hover {
    color: $prime;
    cursor: pointer;

    .check {
      opacity: 1;
      visibility: visible;
      color: $prime;
    }
  }

  input:checked ~ * {
    color: $prime;
  }

  input:checked ~ div {
    .check {
      opacity: 1;
      visibility: visible;
      color: $prime;
    }
  }

  input:checked ~ .toggle {
    background: $prime;

    div {
      margin-left: auto;
    }
  }

  input:checked ~ a button {
    background: $prime;
    background: $prime_gradient_webkit;
    background: $prime_gradient;
    color: $base;
    border-color: $prime;
  }
}

ul {
  list-style: none;
}

.list-circle {
  list-style: disc inside;
}

hr {
  // background-color: $prime;
  background: $prime;
  background: $prime_gradient_webkit;
  background: $prime_gradient;
  border: none;
  height: 0.2em;
  margin: 0 auto;
  border-radius: $border_radius;
}
.hr-thin {
  height: 0.1em;
}

.border {
  border: $border;

  &-second {
    border-color: $second;
  }

  &-txt {
    border-color: $txt;
  }
  &-txt-25 {
    border-color: rgba($txt, 0.25);
  }

  &-transparent {
    border-color: transparent;
  }

  &-dashed {
    border-style: dashed;
  }

  &-radius {
    border-radius: $border_radius;
  }
}

.sphere {
  box-shadow: inset -0.1em -0.1em 0.25em rgba($txt,0.5);
}

.box-shadow {
  box-shadow: 0 0 0.15em rgba($txt, .5);
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
  text-justify: inter-character;
}

.circle {
  border-radius: 100%;
  overflow: hidden;
}

.rounded {
  border-radius: 2em;
}

.c-transparent {
  color: transparent;
}

.capitalize {
  text-transform: capitalize;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.spinner {
  animation: rotate 0.7s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.ratio {
  padding-top: 56.25%;
}

.no-scroll {
  height: 100vh;
  overflow: hidden;
  // position: fixed;
}

.ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}