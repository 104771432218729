.Mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 199;
  background: $base;

  &-header {
    height: 4em;
    display: flex;
  }
}

.display {
  display: block;
}

.no-scroll {
  overflow: hidden !important;
}
