.InfoItem {
    width: calc(33.33% - 2em);
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0 1em;
    margin-bottom: 2em;
    position: relative;

    p {
        text-align: justify;
    }

    @media (max-width: $med) {
        width: calc(100% - 2em);

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}