.Dropdown {
  position: relative;
  width: 100%;

  &-dropdown {
    &-wrapper {
      position: absolute;
      top: 4.15em;
      left: 0;
      display: flex;
      height: 26em;
      box-shadow: $box_shadow;
      border-radius: $border_radius;
      box-sizing: border-box;

      ul {
        display: inline;

        li:hover {
          cursor: pointer;
          background-color: rgba($txt, 0.15) !important;
        }

        .active {
          background-color: rgba($txt, 0.15) !important;
        }

        a {
          padding: 0 !important;

          button {
            justify-content: start !important;
          }
        }
      }
    }

    min-width: 10em;
    height: 100%;
    overflow-y: scroll;
    background: rgba($base, 0.9);
    border-top-left-radius: $border_radius;
    border-bottom-left-radius: $border_radius;
    backdrop-filter: $blur;
    -webkit-backdrop-filter: $blur;

    &-dropout {
      min-width: 10em;
      height: 100%;
      background: rgba($base, 0.9);
      backdrop-filter: $blur;
      -webkit-backdrop-filter: $blur;

      &-dropout {
        width: 30em;
        height: 100%;
        position: relative;
        background: rgba($base, 0.5);
        border-top-right-radius: $border_radius;
        border-bottom-right-radius: $border_radius;
        backdrop-filter: $blur;
        -webkit-backdrop-filter: $blur;

        img {
          max-width: none !important;
          max-height: none !important;
          margin: 0 !important;
          width: 100% !important;

          &:last-of-type {
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        > div {
          svg {
            max-width: none !important;
            max-height: none !important;
            margin: 0 !important;
          }
        }

        a {
          padding: 0 !important;
        }

        &-content {
          position: absolute;
          left: 0;
          right: 0;
          top: calc(56.25% + 2em);
        }
      }
    }

    &-nav {
      position: absolute;
      bottom: 1em;
      left: 50%;
      transform: translateX(-50%) rotate(270deg); // scale(0.75)
      background: rgba($txt, 0.15);
      border-radius: 100%;
      padding: 1em;
      -webkit-backdrop-filter: $blur;
      backdrop-filter: $blur;
      z-index: 2;

      &-inner {
          position: relative;

          svg {
              height: 1em;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              color: rgba($base, 0.5);
          }
      }

      &:hover {
          svg {
              color: rgba($base, 0.75);
          }
      }
    }
  }

  button img {
    height: 1em;
    max-width: 1.2em;
    margin-right: 0.5em;
    object-fit: contain;
  }
}
