.Infobox {
  position: relative;

  svg {
    height: 1em;
    margin-left: 0.5em;
  }

  div {
    display: none;
    position: absolute;
    top: -0.5em;
    left: 2em;
    width: 15em;
    background-color: $base;
    z-index: 2;
    border-left: 0.2em solid $prime;
    padding: 0.5em;
    text-align: left;
    border-top-right-radius: $border_radius;
    border-bottom-right-radius: $border_radius;
  }

  &-left {
    div {
      top: -0.5em;
      left: -16em;
      border-left: none;
      border-right: 0.2em solid $prime;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: $border_radius;
      border-top-left-radius: $border_radius;
    }
  }

  &-bottom {
    div {
      top: 1.5em;
      left: -6.5em;
      border-left: none;
      border-top: 0.2em solid $prime;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border_radius;
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      color: $prime;
    }

    div {
      display: inline;
    }
  }

  @media (max-width: $sm) {
    display: none;
  }
}
